import { colorWhite, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Announcement from "../../Announcement";
import { ImageCtaCard } from "../../Cards";
import IconList from "../../IconList";
import ServiceProviderSignup from "../../ServiceProvider";
import KeyMetrics from "../../XeniumDatasetExplorer/KeyMetrics";
import SectionWithLink from "../../XeniumDatasetExplorer/SectionWithLink";
import TabsWithPills from "../../XeniumDatasetExplorer/TabsWithPills/Block";
import XeniumCardCollection from "../../XeniumDatasetExplorer/XeniumCardCollection";
import XeniumPageSection from "../../XeniumDatasetExplorer/XeniumPageSection";
import Advantage from "../Advantage";
import Assays from "../Assays";
import Collection from "../Collection";
import ComparisonTable from "../ComparisonTable/ComparisonTable";
import CompatibilityTable from "../CompatibilityTable";
import CTA from "../CTA";
import CTAPanel from "../CTAPanel";
import Explore from "../Explore";
import Extensions from "../Extensions";
import FAQs from "../FAQs";
import Feature from "../Feature";
import FeatureOptions from "../FeatureOptions";
import FilterCarousel from "../FilterCarousel";
import Footer from "../Footer";
import Gallery from "../Gallery";
import GetStarted from "../GetStarted";
import HeadingList from "../HeadingList";
import ImageCarousel from "../ImageCarousel";
import Info from "../Info";
import InstrumentComparison from "../InstrumentComparison";
import LinkGroup from "../LinkGroup";
import Masthead from "../Masthead";
import MDX from "../MDX";
import Metrics from "../Metrics";
import NewsSubNav from "../NewsSubNav";
import Overview from "../Overview";
import PageTitle from "../PageTitle";
import PanelOptions from "../PanelOptions";
import Panels from "../Panels";
import PanelsGraphic from "../PanelsGraphic";
import ProductSolutions from "../ProductSolutions";
import RelatedProducts from "../RelatedProducts";
import Requirements from "../Requirements/Requirements";
import RequirementsPanels from "../RequirementsPanels";
import ResourceList from "../ResourceList";
import Resources from "../Resources";
import Results from "../Results";
import Section from "../Section";
import SectionInfo from "../SectionInfo";
import SocialCarousel from "../SocialCarousel";
import Solution from "../Solution";
import SpatialVisualization from "../SpatialVisualization";
import StickyHeader from "../StickyHeader";
import Subnav from "../Subnav";
import Update from "../Update";
import { Workflow, WorkflowShelf } from "../Workflow";

interface Props {
  data?: any;
  isPreview?: boolean;
}

const Block: FunctionComponent<Props> = ({ data, isPreview }) => {
  const { backgroundColor, id, slug, type } = data;

  const [blockTypes] = useState<Record<string, FunctionComponent<any>>>({
    advantage: Advantage,
    announcement: Announcement,
    assays: Assays,
    collection: Collection,
    comparisonTable: ComparisonTable,
    compatibilityTable: CompatibilityTable,
    cta: CTA,
    ctaPanel: CTAPanel,
    explore: Explore,
    extensions: Extensions,
    faqs: FAQs,
    feature: Feature,
    featureOptions: FeatureOptions,
    filterCarousel: FilterCarousel,
    footer: Footer,
    gallery: Gallery,
    getStarted: GetStarted,
    headingList: HeadingList,
    imageCarousel: ImageCarousel,
    imageCtaCard: ImageCtaCard,
    info: Info,
    instrumentComparison: InstrumentComparison,
    keyMetrics: KeyMetrics,
    linkGroup: LinkGroup,
    masthead: Masthead,
    mdx: MDX,
    metrics: Metrics,
    newsSubNav: NewsSubNav,
    overview: Overview,
    pageTitle: PageTitle,
    panelOptions: PanelOptions,
    panels: Panels,
    panelsGraphic: PanelsGraphic,
    productBenefits: IconList,
    productSolutions: ProductSolutions,
    relatedProducts: RelatedProducts,
    requirements: Requirements,
    requirementsPanels: RequirementsPanels,
    resourceList: ResourceList,
    resources: Resources,
    results: Results,
    section: Section,
    sectionInfo: SectionInfo,
    sectionWithLink: SectionWithLink,
    serviceProviderSignup: ServiceProviderSignup,
    socialCarousel: SocialCarousel,
    solution: Solution,
    spatialVisualization: SpatialVisualization,
    stickyHeader: StickyHeader,
    subnav: Subnav,
    tabsWithPills: TabsWithPills,
    update: Update,
    workflow: Workflow,
    workflowShelf: WorkflowShelf,
    xeniumCardCollection: XeniumCardCollection,
    xeniumPageSection: XeniumPageSection,
  });

  // Some block functionality - for example, main site navigation -
  // is problematic or even explosive in Charlie previews. For that
  // reason we completely disable some block types in preview mode.
  const [disabledInPreview] = useState([
    "announcement",
    "explore",
    "footer",
    "getStarted",
    "masthead",
    "relatedProducts",
    "results",
  ]);

  const getElement = (type: string) => {
    switch (type) {
      case "announcement":
        return "announcement";
      case "footer":
        return "footer";
      case "masthead":
        return "header";
      default:
        return "section";
    }
  };

  const Element = getElement(type);
  const Content = blockTypes[type];
  const isDisabledInPreview = disabledInPreview.includes(type) ? "none" : "all";
  const [background, setBackground] = useState(backgroundColor || colorWhite);

  // FIXME Can't be disabled in preview mode currently
  if (Element === "footer") {
    const footerProps = data.data ? data.data : data;
    return <Footer data={footerProps} />;
  }

  // We don't want the announcement banner to
  // get wrapped in an outer block element.
  if (Element === "announcement") {
    return <Announcement />;
  }

  return Content ? (
    <Element
      css={css`
        background: ${background};
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        pointer-events: ${isPreview ? isDisabledInPreview : "all"};

        @media (max-width: ${mediaTabletLandscape}) {
          justify-content: flex-start;

          > * {
            overflow-x: scroll;
          }
        }
      `}
      id={id || slug}
    >
      <Content {...data} isPreview={isPreview} setBackground={setBackground} />
    </Element>
  ) : null;
};

export default Block;
