import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: string;
  colspan?: number;
}

const Cell: FunctionComponent<Props> = ({ children, className, colspan }) => {
  const CellStyle = css`
    padding: 1.5rem;
    vertical-align: top;
    ${colspan ? `text-align: center;` : ``}
  `;

  return (
    <td className={className} colSpan={colspan} css={CellStyle}>
      {children}
    </td>
  );
};

export default Cell;
