import type { TextSize } from "@10x/types";
import { borderLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import Anchor from "../../Anchor/Anchor";
import Cell from "../../ComparisonTable/Cell";
import RowHead from "../../ComparisonTable/RowHead";
import Table from "../../ComparisonTable/Table";
import TableBody from "../../ComparisonTable/TableBody";
import TableHead from "../../ComparisonTable/TableHead";
import Icon from "../../Icon/Icon";
import type { IconSource } from "../../Icon/types";
import Image from "../../Image/Image";
import Text from "../../Text/Text";
import type { MarkerContent } from "../Section/BulletedList";
import BulletedList from "../Section/BulletedList";
import type { Props as ContainerProps } from "../Section/Layout/Container";
import Container from "../Section/Layout/Container";

type ItemAttrs = {
  colspan?: number;
  id: string;
  links?: {
    icon?: IconSource;
    label: string;
    url: string;
  }[];
  list?: string[];
  marker?: {
    content: MarkerContent;
    gap?: string | number;
  };
  text?: string[];
  title?: string;
};

interface Props {
  attributes: {
    id: string;
    label: string;
  }[];
  columnWidth?: string;
  comparison?: string;
  container?: ContainerProps;
  items: {
    attributes: ItemAttrs[];
    image?: string;
    links?: {
      icon?: IconSource;
      label: string;
      url: string;
    }[];
    tile?: {
      src: string;
      width?: string;
    };
    title: string;
    titleSize?: TextSize;
  }[];
}

const ComparisonTable = ({
  attributes,
  columnWidth = "auto",
  container,
  comparison = "",
  items,
}: Props) => {
  return (
    <Container
      {...container}
      css={css`
        overflow-x: auto;
        padding-left: calc((100% - 1200px) / 2);
        padding-right: calc((100% - 1200px) / 2);
      `}
    >
      <Table
        columns={items.length + 1}
        css={css`
          background: ${colorWhite};
          max-width: 100%;
          width: ${container?.width || "100%"};
          @media (max-width: 1200px) {
            box-sizing: border-box;
            margin-left: 1rem;
            width: 100%;
          }
        `}
      >
        <TableHead>
          <RowHead
            css={css`
              width: 240px;
            `}
            label={comparison}
          />
          {items.map((item) => {
            const { titleSize = "medium" } = item;
            return (
              <Cell
                css={css`
                  padding: 1.5rem 1rem;
                  width: ${columnWidth};
                `}
                key={item.title}
              >
                {item.tile ? (
                  <Image
                    alt={item.title}
                    css={css`
                      margin: 0 auto 1.5rem;
                    `}
                    src={item.tile.src}
                    width={item.tile.width || "100px"}
                  />
                ) : null}
                <Text
                  as={"h3"}
                  color={"base"}
                  css={css`
                    text-align: center;
                    margin-bottom: 1.5rem;
                  `}
                  size={titleSize}
                  weight={"semibold"}
                >
                  {item.title}
                </Text>
                {item.image ? (
                  <Image
                    alt={item.title}
                    css={css`
                      margin: 0 auto 1.5rem;
                    `}
                    src={item.image}
                    width={"240px"}
                  />
                ) : null}
                <div
                  css={css`
                    display: flex;
                    gap: 1.5rem;
                    justify-content: center;
                  `}
                >
                  {item?.links?.length
                    ? item.links.map((link) => {
                        return (
                          <Anchor
                            css={css`
                              align-items: center;
                              display: flex;
                              gap: 0.75rem;
                              height: 2rem;
                              width: max-content;
                            `}
                            href={link.url}
                            key={link.url}
                          >
                            <Text
                              as={"span"}
                              color={"inherit"}
                              responsive={true}
                              size={"small"}
                              weight={"semibold"}
                            >
                              {link.label}
                            </Text>
                            {link.icon ? (
                              <Icon
                                color={"inherit"}
                                size={"12px"}
                                source={link.icon}
                                yPos={"1px"}
                              />
                            ) : null}
                          </Anchor>
                        );
                      })
                    : null}
                </div>
              </Cell>
            );
          })}
        </TableHead>
        <TableBody>
          {attributes.map((currAttr) => {
            return (
              <tr
                css={css`
                  border-bottom: ${borderLighter};
                `}
                key={currAttr.id}
              >
                <RowHead label={currAttr.label} />
                {items.map((item) => {
                  const currentItem = item.attributes.filter(
                    (itemAttr) => itemAttr.id === currAttr.id,
                  )[0];

                  return (
                    <>
                      {currentItem ? (
                        <Cell colspan={currentItem?.colspan} key={uuidv4()}>
                          <div
                            css={css`
                              display: grid;
                              gap: 1rem;
                            `}
                          >
                            {currentItem?.title || currentItem?.text?.length ? (
                              <div>
                                {currentItem?.title ? (
                                  <Text
                                    as={"p"}
                                    size={"small"}
                                    weight={"semibold"}
                                  >
                                    {currentItem?.title}
                                  </Text>
                                ) : null}

                                {currentItem?.text?.length
                                  ? currentItem?.text.map((text) => {
                                      return (
                                        <Text
                                          as={"p"}
                                          key={text}
                                          size={"small"}
                                        >
                                          {text}
                                        </Text>
                                      );
                                    })
                                  : null}
                              </div>
                            ) : null}

                            {currentItem?.list?.length ? (
                              <ul
                                css={css`
                                  padding: 0;
                                  margin: 0;
                                `}
                              >
                                <BulletedList
                                  list={currentItem?.list}
                                  marker={currentItem.marker}
                                  size={"small"}
                                />
                              </ul>
                            ) : null}

                            {currentItem?.text?.length ||
                            currentItem?.list?.length ? null : (
                              <Text as={"p"} size={"small"}>
                                {"-"}
                              </Text>
                            )}

                            {currentItem?.links?.length
                              ? currentItem.links.map((link) => {
                                  return (
                                    <Anchor
                                      css={css`
                                        align-items: center;
                                        display: flex;
                                        gap: 0.75rem;
                                        height: 2rem;
                                        width: max-content;
                                      `}
                                      href={link.url}
                                      key={link.url}
                                    >
                                      <Text
                                        as={"span"}
                                        color={"inherit"}
                                        responsive={true}
                                        size={"small"}
                                        weight={"semibold"}
                                      >
                                        {link.label}
                                      </Text>
                                      {link.icon ? (
                                        <Icon
                                          color={"inherit"}
                                          size={"12px"}
                                          source={link.icon}
                                          yPos={"1px"}
                                        />
                                      ) : null}
                                    </Anchor>
                                  );
                                })
                              : null}
                          </div>
                        </Cell>
                      ) : null}
                    </>
                  );
                })}
              </tr>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ComparisonTable;
